const global_const = {
  projectStatus: [
    { value: 'scheduled', text: '예정' },
    { value: 'complete', text: '완료' },
    { value: 'cancel', text: '취소' },
    { value: 'noShow', text: '노쇼' },
    { value: 'reschedule', text: '연기' },
    { value: 'waiting', text: '대기' },
    { value: 'inProgress', text: '진행중' },
  ],
  statusColor: {
    scheduled: 'info',
    complete: 'success',
    cancel: 'error',
    noShow: 'warning',
    reschedule: 'primary',
    waiting: 'secondary',
    inProgress: 'primary',
  },
  gender: [
    {
      value: 'male',
      text: '남성',
    },
    {
      value: 'female',
      text: '여성',
    },
    {
      value: 'other',
      text: '기타',
    },
  ],
  marketingChannel: [
    { value: 'ameblo', text: '아메바' },
    { value: 'twitter', text: 'X(트위터)' },
    { value: 'instagram', text: '인스타그램' },
    { value: 'threads', text: '스레드' },
    { value: 'facebook', text: '페이스북' },
    { value: 'wechat', text: '위챗' },
    { value: 'xiaohongshu', text: '샤오홍슈' },
    { value: 'etc', text: '기타' },
  ],
  reviewType: [
    { value: 'customer', text: '고객 후기' },
    { value: 'partner', text: '에이전시 후기' },
  ],
  platforms: [
    { value: 'gangnamunni', text: '강남언니' },
    { value: 'navercafe', text: '네이버 카페' },
    { value: 'naverblog', text: '네이버 블로그' },
    { value: 'feko', text: '여우야' },
    { value: 'weibo', text: '웨이보' },
    { value: 'etc', text: '기타' },
  ],
  specialty: [
    {
      value: 'dental',
      text: '치과',
    },
    {
      value: 'dermatology',
      text: '피부과',
    },
    {
      value: 'plastic_surgery',
      text: '성형외과',
    },
    {
      value: 'orthopedic',
      text: '정형외과/외과',
    },
    {
      value: 'hospital',
      text: '병원',
    },
    {
      value: 'pain_management',
      text: '마취통증의학과',
    },
    {
      value: 'neurology',
      text: '신경과/신경외과',
    },
    {
      value: 'rehabilitation',
      text: '재활의학과',
    },
    {
      value: 'internal_medicine',
      text: '내과',
    },
    {
      value: 'pediatrics',
      text: '소아청소년과',
    },
    {
      value: 'ophthalmology',
      text: '안과',
    },
    {
      value: 'otolaryngology',
      text: '이비인후과',
    },
    {
      value: 'psychiatry',
      text: '정신건강의학과',
    },
    {
      value: 'obstetrics_gynecology',
      text: '산부인과',
    },
    {
      value: 'urology',
      text: '비뇨기과',
    },
    {
      value: 'traditional_medicine',
      text: '한의원',
    },
    {
      value: 'general_practice',
      text: '일반의원',
    },
  ],
  // 누적 환자 유치 수
  cumulative_patients: [
    { value: 1, text: '0~500' },
    { value: 2, text: '500+' },
    { value: 3, text: '1000+' },
    { value: 4, text: '2000+' },
    { value: 5, text: '3000+' },
    { value: 6, text: '5000+' },
    { value: 7, text: '10000+' },
  ],
  hospital_scales: [
    { value: 1, text: '의원', description: '병상 수 30개 미만, 주로 외래 환자 진료' },
    { value: 2, text: '병원', description: '병상 수 30개 이상, 입원 환자 진료' },
    { value: 3, text: '종합병원', description: '병상 수 100개 이상, 여러 진료 과목 운영' },
    { value: 4, text: '상급종합병원', description: '중증 질환 전문 치료, 병상 수 500개 이상' },
    { value: 5, text: '전문병원', description: '특정 질환 전문 치료, 보건복지부 지정 필요' },
  ],
  regions: [
    { value: 'US', text: '미국(영어권국가)' },
    { value: 'JP', text: '일본' },
    { value: 'CN', text: '중국' },
    { value: 'ME', text: '중동' },
    { value: 'RU', text: '러시아(CIS 연합)' },
    { value: 'MN', text: '몽골' },
    { value: 'VN', text: '베트남' },
    { value: 'ETC', text: '기타' },
  ],
  korea_region: [
    { value: 'GW', text: '강원' },
    { value: 'GG', text: '경기' },
    { value: 'GN', text: '경남' },
    { value: 'GB', text: '경북' },
    { value: 'GJ', text: '광주' },
    { value: 'DG', text: '대구' },
    { value: 'DJ', text: '대전' },
    { value: 'BS', text: '부산' },
    { value: 'SL', text: '서울' },
    { value: 'SJ', text: '세종' },
    { value: 'US', text: '울산' },
    { value: 'IC', text: '인천' },
    { value: 'JN', text: '전남' },
    { value: 'JB', text: '전북' },
    { value: 'JJ', text: '제주' },
    { value: 'CN', text: '충남' },
    { value: 'CB', text: '충북' },
  ],
  // 생년월일 select array
  // 지금 년도에서 100년 전까지의 생년월일을 만들어줌
  birthYear: Array.from({ length: 100 }, (v, i) => {
    return {
      value: new Date().getFullYear() - i,
      text: new Date().getFullYear() - i,
    };
  }),
  // 생월 1~12의 select array
  birthMonth: Array.from({ length: 12 }, (v, i) => {
    return {
      value: i + 1,
      text: i + 1,
    };
  }),
  // 생일 1~31까지의 select array
  birthDay: Array.from({ length: 31 }, (v, i) => {
    return {
      value: i + 1,
      text: i + 1,
    };
  }),

  // 회원 타입
  user_type_list: [
    { value: 'hospital', text: '병원' },
    { value: 'partners', text: '에이전시' },
  ],
  // 회원 권한
  user_role_list: [
    { value: 'admin', text: '소유자' },
    { value: 'master', text: '관리자' },
    { value: 'staff', text: '구성원' },
  ],

  //통역서비스 제공여부
  interpretationService: [
    { value: 1, text: '제공' },
    { value: 0, text: '미제공' },
  ],

  // 매칭상태
  matching_status_list: [
    {
      value: 'request',
      text: '매칭 신청',
      color: 'secondary',
    },
    {
      value: 'complete',
      text: '매칭 완료',
      color: 'primary',
    },
    {
      value: 'reject',
      text: '매칭 거절',
      color: 'error',
    },
    {
      value: 'cancel',
      text: '매칭 취소',
      color: 'error',
    },
  ],

  // 시간 00:00~23:30까지 30분 단위로
  timeList: Array.from({ length: 48 }, (v, i) => {
    const hour = Math.floor(i / 2);
    const minute = i % 2 === 0 ? '00' : '30';
    return {
      value: `${hour < 10 ? '0' : ''}${hour}:${minute}`,
      text: `${hour < 10 ? '0' : ''}${hour}:${minute}`,
    };
  }),
};

export default global_const;
