<!-- VCustomDate.vue -->
<script setup>
import { computed, ref, watch } from 'vue';
import moment from 'moment';

const props = defineProps({
  modelValue: {
    type: [String, Date, null],
    default: '',
  },
  label: {
    type: String,
    default: '날짜 선택',
  },
  variant: {
    type: String,
    default: 'outlined',
  },
});

const emit = defineEmits(['update:modelValue']);

const date = ref(props.modelValue ? moment(props.modelValue).format('YYYY-MM-DD') : '');
const datePicker = ref(null);

const formattedDate = computed(() => {
  if (!date.value) return '';
  // const [year, month, day] = date.value.split('-');
  // return `${year}-${month}-${day}`;
  return moment(date.value).format('YYYY-MM-DD');
});

const datePickerStyle = computed(() => ({
  position: 'absolute',
  opacity: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
}));

const openDatePicker = () => {
  if (datePicker.value) {
    datePicker.value.showPicker();
  }
};

const updateDate = event => {
  const newDate = event.target.value;
  date.value = newDate;
  emit('update:modelValue', newDate);
};

watch(
  () => props.modelValue,
  newValue => {
    date.value = newValue ? moment(newValue).format('YYYY-MM-DD') : '';
  }
);
</script>
<template>
  <div class="v-custom-date">
    <v-text-field
      :model-value="date ? date : formattedDate"
      :label="label"
      readonly
      @click="openDatePicker"
      append-inner-icon="mdi-calendar"
      :variant="props.variant ? props.variant : 'outlined'"
      @click:append="openDatePicker"
    ></v-text-field>
    <input
      type="date"
      :value="date"
      @input="updateDate"
      ref="datePicker"
      :style="datePickerStyle"
    />
  </div>
</template>

<style scoped>
.v-custom-date {
  position: relative;
}

input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}
</style>
